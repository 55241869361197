import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./index.module.css"

import Container from "../components/container.js"

const Contact = () => {
	
	return (
		<div>
			<Container>
			<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc2jli3NNXOAnG-W85ZQDAKJdycEyccr6NQKMahgS8oGDhDvA/viewform?embedded=true" width="100%" height="110%" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
			</Container>
		</div>
	)
}

export default Contact